@keyframes modal-open {
  from {
    opacity: 0;
    transform: translate(0, -25%);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.animate {
  animation: modal-open ease-in 300ms;
}
